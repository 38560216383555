import { useAccount } from 'wagmi'
import { useCallback, useContext, useState } from 'react'
import { ComplianceStatus, KYCSource, KYCStatus } from 'state/types'
import { getWithExpiry, setWithExpiry } from 'utils/localStorageHelper'

import useSWR from 'swr'
import { CompanyContext } from 'contexts/CompanyContext'

const USER_STORAGE_KEY = 'USER_STATUS_DATA'
const URL = process.env.REACT_APP_BACKEND_URL

type KYCResponse = {
  status: KYCStatus
  complianceStatus: ComplianceStatus
  complianceCountry?: string
  kycSource?: KYCSource
  info?: string
  refreshTrigger: () => void
}

export const useKycStatus = (): KYCResponse => {
  const { address: account } = useAccount()
  const { isJustSale, requireKYC, kycSource } = useContext(CompanyContext)
  const [refreshCache, setRefreshCache] = useState(0)

  const refreshTrigger = useCallback(() => {
    setRefreshCache((prev) => prev + 1)
  }, [])

  let cacheKey = account?.length > 0 ? `${USER_STORAGE_KEY}_${account}` : USER_STORAGE_KEY
  cacheKey = kycSource?.length > 0 ? `${cacheKey}_${kycSource}` : kycSource

  const getStatus = useCallback(async () => {
    if (!account) {
      console.info('Wallet not connected')
      return false
    }

    // Migrate to new backend API
    try {
      const cachedData = getWithExpiry(cacheKey, 5)

      if (isJustSale && !requireKYC) {
        return {
          refreshTrigger,
          status: KYCStatus.NOT_FOUND,
          complianceStatus: ComplianceStatus.EMPTY,
          complianceCountry: '',
        }
      }

      if (cachedData) {
        return cachedData
      }
      // New backend API
      const ENDPOINT =
        kycSource?.length > 0
          ? `${URL}/api/backend-service/address/${account}/status?source=${kycSource}`
          : `${URL}/api/backend-service/address/${account}/status`
      const response = await (
        await fetch(ENDPOINT, {
          method: 'GET',
          headers: [['Cache-Control', 'no-cache']],
        })
      ).json()

      if (response.status === 'VERIFIED') {
        setWithExpiry(cacheKey, response, 24 * 60 * 60 * 1000, 3)
      }

      return response
    } catch (e) {
      console.info('KYC status error', e)
      return false
    }
  }, [account, refreshCache])

  const { data = {} } = useSWR([`useKycStatus|${account}`], getStatus, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  })

  if (data) {
    data.complianceStatus = data.compliance_status || ComplianceStatus.EMPTY
    data.kycSource = data.kyc_source || ''
    data.complianceCountry = data.compliance_country ? data.compliance_country : data.country || ''
    data.refreshTrigger = refreshTrigger

    // Change here for quick status change
    // data.kycSource = 'AUTHENTO'
    // data.status = KYCStatus.VERIFIED

    // If verified, it is rare for this user to be unverified, let's cache it for 24 hours
    if (data.status === KYCStatus.VERIFIED && data.complianceStatus === ComplianceStatus.VALID) {
      setWithExpiry(cacheKey, data, 24 * 60 * 60 * 1000, 5)
    }

    return data
  }
  return {
    refreshTrigger,
    status: KYCStatus.NOT_FOUND,
    complianceStatus: ComplianceStatus.EMPTY,
    complianceCountry: '',
  }
}

export default useKycStatus
