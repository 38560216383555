import { useAccount } from 'wagmi'
import { useCallback, useContext, useState } from 'react'
import { useToast } from 'state/hooks'
import { mutate } from 'swr'

import SynapsClient from '../componentsV2/SynapsModal'
import { CompanyContext } from 'contexts/CompanyContext'

// kyc session URL
const BASE_URL = process.env.REACT_APP_BACKEND_URL
const ENDPOINT = `${BASE_URL}/api/backend-service/synaps/session/init`
// Sign message via wallet to verify ownership of address
export const useKycSession = () => {
  // const dispatch = useDispatch()
  const { address: account } = useAccount()
  const [isLoading, setLoading] = useState(false)
  const { toastErrorV2 } = useToast()
  const { kycSource } = useContext(CompanyContext)

  const url = new URL(ENDPOINT)

  const handleSigning = useCallback(
    async (type?: string) => {
      if (!account) {
        console.info('Wallet not connected')
        return false
      }
      setLoading(true)
      try {
        if (kycSource?.length > 0) {
          url.searchParams.set('source', kycSource)
        }

        if (type?.length > 0) {
          url.searchParams.set('type', type)
        }

        const sessionId = (
          await (
            await fetch(url, {
              method: 'POST',
              credentials: 'include',
              headers: [['Cache-Control', 'no-cache']],
            })
          ).json()
        ).data

        if (!sessionId) {
          throw new Error('Something is wrong, make sure you have the minimum IDIA or IF')
        }

        // initialize and open synaps modal
        try {
          const Synaps = new SynapsClient(sessionId, 'workflow')
          Synaps.init()
          Synaps.on('close', () => {
            // callback on modal close
            localStorage.removeItem(`KYC_STATUS_LS-${account}`)
            mutate(`useKycStatus|${account}`)
            setLoading(false)
          })
          Synaps.on('finish', () => {
            // callback on flow finish (completed onboarding)
            localStorage.removeItem(`KYC_STATUS_LS-${account}`)
            mutate(`useKycStatus|${account}`)
            setLoading(false)
          })
          Synaps.openSession()
        } catch (e: any) {
          setLoading(false)
          toastErrorV2('Error', e.message)
          return false
        }

        return true
      } catch (e: any) {
        console.info('KYC session error', e)
        setLoading(false)
        toastErrorV2('Error', e.message || 'Something went wrong')
        return false
      }
    },
    [account, toastErrorV2],
  )

  return { onSession: handleSigning, isLoading }
}

export default useKycSession
